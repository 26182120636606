import React from "react";
import "./blog.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CallToActionSection from "../commonComponent/CallToActionSection";
import { useRef } from "react";
const BoostingEmployeeAccountability = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isactive, setisActive] = useState(null);

  const sectionRefs = {
    section0: useRef(null),
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
  };
  const scrollToSection = (section) => {
    const element = sectionRefs[section].current;
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };

  // Scroll event handler to detect which section is in view
  useEffect(() => {
    const handleScroll = () => {
      const sections = Object.keys(sectionRefs); // Get keys (section names)
      let foundActiveSection = "";

      // Check each section to see if it's in the viewport
      sections.forEach((section) => {
        const element = sectionRefs[section].current;
        const rect = element.getBoundingClientRect();

        // If the section is in the viewport (based on top and bottom visibility)
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          foundActiveSection = section; // Update active section
        }
      });

      if (foundActiveSection !== "") {
        setisActive(foundActiveSection); // Set active section
      }
    };

    // Listen to scroll events
    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const blogTitles = [
    {
      title:
        "Top Benefits of Implementing Attendance Management Software in Your Business",
      img: "assets/img/blog/Top_Benefits_of_Implementing.jpg",
      alt: "Benefits of Implementing Attendance Management image",
      link: "/blog-top-benefits-of-implementing-attendance-management-software-in-your-business",
    },
    {
      title:
        "The Future of Employee Time and Attendance: Trends in Attendance Management Software",
      img: "assets/img/blog/The_Future_of_Employee_Time.jpg",
      alt: "The Future of Employee Time and Attendance image",
      link: "/blog-future-of-employee-time-and-attendance-trends-in-management-software",
    },
    {
      title:
        "How Efficient Attendance Management Boosts Productivity and Reduces Costs",
      img: "assets/img/blog/How_Efficient_Attendance_Management.jpg",
      alt: "How Efficient Attendance Management Boosts image",
      link: "/blog-how-efficient-attendance-management-boosts-productivity-reduces-costs",
    },
    {
      title: "Manual vs. Automated Attendance Management",
      img: "assets/img/blog/Manual_vs._Automated.jpg",
      alt: "Manual vs. Automated Attendance Management image",
      link: "/blog-manual-vs-automated-attendance-management-why-technology-wins",
    },
    {
      title:
        "How Attendance Management Software Improves Accuracy and Saves Time",
      img: "assets/img/blog/How_Attendance_Management.jpg",
      alt: "How Attendance Management Software Improves Accuracy image",
      link: "/blog-how-attendance-management-software-improves-accuracy-and-saves-time",
    },
    {
      title: "Why Your Company Needs a Modern Attendance Management System",
      img: "assets/img/blog/why-your-company-needs-a-modern-attendance-management-system.jpg",
      alt: "why-your-company-needs-a-modern-attendance-management-system",
      link: "/blog-why-your-company-needs-a-modern-attendance-management-system",
    },
    {
      title: "Advanced Attendance Tracking Solutions Help Reduce Time Theft",
      img: "assets/img/blog/how-attendance-management-tracking-reduces-time-theft.jpg",
      alt: "how-attendance-management-tracking-reduces-time-theft",
      link: "/blog-advanced-attendance-tracking-solutions-help-reduce-time-theft",
    },
    {
      title:
        "Streamlining HR Operations: The Role of Attendance Management Software",
      img: "assets/img/blog/the-role-of-attendace-management-software-in-hr-operation.jpg",
      alt: "the-role-of-attendace-management-software-in-hr-operation",
      link: "/blog-streamlining-hr-operations-the-role-of-attendance-management-software",
    },
    {
      title: "Key Features to Look for in an Attendance Management System",
      img: "assets/img/blog/key-features-attendance-management-system.jpg",
      alt: "key-features-attendance-management-system",
      link: "/blog-key-features-to-Look-for-in-an-attendance-management-system",
    },
    {
      title: "5 Common Attendance Management Challenges and How to Solve Them",
      img: "assets/img/blog/5-common-attendance-management-challenges-and-solutions.jpg",
      alt: "5-common-attendance-management-challenges-and-solutions",
      link: "/blog-5-common-attendance-management-challenges-and-how-to-solve-them",
    },

    {
      title: "Enhancing Remote Work Management with Attendance Management App",
      img: "assets/img/blog/Enhancing Remote Work Management with Attendance.jpg",
      alt: "Enhancing Remote Work Management with Attendance Management App",
      link: "/blog-enhancing-remote-work-management-with-attendance-management-app",
    },
    {
      title:
        "The Importance of Attendance Management for Compliance and Audits",
      img: "assets/img/blog/The Importance of Attendance Management for.jpg",
      alt: "The Importance of Attendance Management for Compliance and Audits",
      link: "/blog-the-importance-of-attendance-management-for-Compliance-and-audits",
    },
    {
      title:
        "How to Choose the Right Attendance Management Software for Your Business",
      img: "assets/img/blog/How to Choose the Right Attendance Management Software for.jpg",
      alt: "How to Choose the Right Attendance Management Software for Your Business",
      link: "/blog-how-to-choose-the-right-attendance-management-software-for-your-business",
    },
    {
      title:
        "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      img: "assets/img/blog/Real-Time Attendance Tracking Benefits for Large and.jpg",
      alt: "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      link: "/blog-real-time-attendance-tracking-benefits-for-large-and-small-businesses",
    },
  ];

  const listItems = [
    {
      id: "section0",
      label: "Key Features of TimeTango",
    },
    {
      id: "section1",
      label: "Enhancing Employee Accountability with TimeTango",
    },
    {
      id: "section2",
      label: "Why Choose TimeTango?",
    },
    {
      id: "section3",
      label: "Transform Your Workforce Management Today",
    },
    {
      id: "section4",
      label: "Get Started with TimeTango",
    },
  ];

  return (
    <>
      <section className="w-100 bccolor heights">
        <div className="text-center fs-18 mb-3">
          <Link to={"/blogs"}>Blog</Link>/Boosting Employee Accountability with
          Smart Attendance Management Solutions
        </div>
        <h1 className="text-center mb-5 ">
          Boosting Employee Accountability with Smart Attendance Management
          Solutions
        </h1>
      </section>
      {/* component 2 */}
      <section
        className="p-20 "
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="row w-100 justify-content-center gap-2">
          <div className="col-3 card-blog hidden" style={{ height: "300px" }}>
            <ul className="p-2 mt-3 mb-3 ulist" style={{ cursor: "pointer" }}>
              {listItems.map((item, index) => (
                <li
                  key={index}
                  className={`p-1 ${isactive === item.id ? "active" : null}`}
                  onClick={() => {
                    scrollToSection(item.id);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12 col-md-12 col-lg-7 ">
            <div className="mb-4">
              <p className="fs-16-js mb-4">
                Are you tired of the inefficiencies and hassles of traditional
                attendance systems? It’s time to say goodbye to outdated paper
                sign-ins and cumbersome HR paperwork. TimeTango brings a
                revolutionary approach to attendance tracking, offering a
                seamless, efficient, and ecofriendly solution.
              </p>
              <p className="fs-16-js mb-4">
                In today&#39;s fast paced business landscape, efficient employee
                management is not only an operational necessity but a strategic
                advantage. This critical need, we have recognized, and so we at
                TimeTango have developed a state-of-the-art{" "}
                <Link
                  to="/leave-mangement"
                  className="text-decoration-none fw-bold"
                >
                  Employee Attendance Management System
                </Link>{" "}
                to simplify HR operations and enable companies of all scales to
                achieve their maximum potential.
              </p>
            </div>

            {/* component one */}
            <div className="mb-4" ref={sectionRefs.section0}>
              <h2 className="fs-3 fw-bold">Key Features of TimeTango:</h2>
              <h3 className="fs-4 fw-bold">1. Seamlessly Convenient</h3>
              <ul className="custom-dot-list">
                <li>
                  Employees can check-in and check-out effortlessly with a
                  simple tap on their mobile devices.
                </li>
                <li>
                  The system captures a secure image for authentication,
                  ensuring reliable attendance records.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">2. Advanced Image Authentication</h3>
              <ul className="custom-dot-list">
                <li>
                  Our cutting-edge image authentication prevents timesheet
                  manipulation and buddy punching.
                </li>
                <li>
                  This feature ensures accountability and builds trust within
                  your organization.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">3. Paperless and Eco-Friendly</h3>
              <ul className="custom-dot-list">
                <li>
                  By eliminating the need for paper-based attendance tracking,
                  TimeTango promotes sustainability.
                </li>
                <li>
                  Reducing paper waste contributes to a greener, more
                  eco-friendly workplace.
                </li>{" "}
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">4. Real-Time Insights</h3>
              <ul className="custom-dot-list">
                <li>
                  Admins receive instant notifications about employee check-in
                  and check-out activities.
                </li>
                <li>
                  HR teams can monitor working hours, track early departures,
                  and gain actionable insights in real time.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">5. User-Friendly Interface</h3>
              <ul className="custom-dot-list">
                <li>
                  Designed with simplicity in mind, TimeTango is easy to use for
                  both employees and admins.
                </li>
                <li>
                  Minimal training is required, enabling organizations to
                  maximize efficiency from day one.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">6. Detailed Compliance Reports</h3>
              <ul className="custom-dot-list">
                <li>
                  HR teams can access comprehensive compliance reports on a
                  daily or monthly basis.
                </li>
                <li>
                  These reports help in identifying attendance patterns,
                  ensuring compliance with company policies, and supporting
                  performance evaluations.
                </li>
              </ul>
            </div>

            {/* component */}
            <div className="mb-4" ref={sectionRefs.section1}>
              <h2 className="fs-3 fw-bold mb-4">
                Enhancing Employee Accountability with TimeTango
              </h2>
              <h3 className="fs-4 fw-bold">
                How TimeTango Drives Accountability
              </h3>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">
                1. Transparent Attendance Records
              </h3>
              <ul className="custom-dot-list">
                <li>
                  Advanced tracking ensures that employees’ attendance data is
                  accurate and tamper-proof.
                </li>
                <li>
                  <Link
                    to="/real-time-insight"
                    className="text-decoration-none fw-bold"
                  >
                    Real-time updates
                  </Link>
                  {"   "}
                  help in maintaining transparency and reducing discrepancies.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">2. Encouraging Punctuality</h3>
              <ul className="custom-dot-list">
                <li>
                  With instant notifications and real-time insights, employees
                  are more conscious of their check-in and check-out times.
                </li>
                <li>
                  This fosters a culture of punctuality and responsibility.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">3. Streamlined HR Processes</h3>
              <ul className="custom-dot-list">
                <li>
                  <Link
                    to="/leave-mangement"
                    className="text-decoration-none fw-bold"
                  >
                    Automated attendance management
                  </Link>
                  {"   "}
                  reduces manual workload, freeing up HR teams to focus on
                  strategic initiatives.
                </li>
                <li>
                  Real-time insights enable HR to address attendance issues
                  promptly and effectively.
                </li>
              </ul>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">4. Improved Employee Engagement</h3>
              <ul className="custom-dot-list">
                <li>
                  A user-friendly system boosts employee satisfaction, as they
                  can easily manage their attendance without delays or
                  complications.
                </li>
                <li>
                  Features like eco-friendly paperless operations resonate with
                  employees who value sustainability.
                </li>
              </ul>
            </div>

            {/* component*/}
            <div className="mb-4" ref={sectionRefs.section2}>
              <h2 className="fs-3 fw-bold">Why Choose TimeTango?</h2>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">
                1. Adaptable to Any Business Size
              </h3>
              <p className="fs-16-js">
                Whether you are a small startup or a large enterprise, TimeTango
                scales effortlessly to meet your needs.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">2. Secure and Reliable</h3>
              <p className="fs-16-js">
                Robust data encryption ensures that attendance records are
                secure and confidential.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">3. Cost-Efficient</h3>
              <p className="fs-16-js">
                By automating attendance tracking and reducing paper dependency,
                TimeTango helps cut operational costs.
              </p>
            </div>
            <div className="mb-4">
              <h3 className="fs-4 fw-bold">4. Enhanced Decision-Making</h3>
              <p className="fs-16-js">
                Access to detailed reports and analytics allows leaders to make
                data-driven decisions for workforce management.
              </p>
            </div>

            {/* component */}
            <div className="mb-4" ref={sectionRefs.section3}>
              <h2 className="fs-3 fw-bold">
                Transform Your Workforce Management Today
              </h2>
            </div>
            <div>
              <h3 className="fs-4 fw-bold">
                Step into the Future of HR Operations
              </h3>
              <p className="fs-16-js">
                By adopting TimeTango, your organization can
              </p>
              <ul className="custom-dot-list">
                <li>Streamline attendance tracking.</li>
                <li>Enhance employee accountability and engagement.</li>
                <li> Reduce the environmental footprint of your operations.</li>
              </ul>
              <p className="fs-16-js">
                In the modern world where efficiency and sustainability are
                crucial, TimeTango is the best way to enhance{" "}
                <Link
                  to="/leave-mangement"
                  className="text-decoration-none fw-bold"
                >
                  employee time and attendance management.
                </Link>{" "}
                With the elimination of traditional challenges and the use of
                new technology your organization can achieve new levels of
                productivity and engagement. Choose TimeTango today and take a
                decisive step toward a smarter, more efficient workplace.
              </p>
            </div>

            {/* component */}
            <div className="mb-4" ref={sectionRefs.section4}>
              <h2 className="fs-3 fw-bold">Get Started with TimeTango</h2>
              <p className="fs-16-js">
                Ready to revolutionize your{" "}
                <Link
                  to="/leave-mangement"
                  className="text-decoration-none fw-bold"
                >
                  attendance management
                </Link>{" "}
                process? TimeTango is here to help you every step of the way.
                {"  "}
                <Link to="/contact-us" className="text-decoration-none fw-bold">
                  Contact us
                </Link>{" "}
                for a demo and experience the TimeTango difference.
              </p>
            </div>
          </div>

          <div
            className="col-3 hidden"
            style={{
              maxHeight: "100vw",
              overflowY: "auto",
              scrollbarWidth: "none",
              position: "sticky",
              top: 0,
            }}
          >
            <p className="text-center fs-18 fw-bold">Other blog</p>
            <div className="d-flex justify-content-center flex-column gap-4">
              {blogTitles.map((item, index) => (
                <div key={index} className="card-sub-blog">
                  <div>
                    <Link to={item.link}>
                      <img
                        src={item.img}
                        alt={item.alt}
                        className="w-100 h-100"
                      />
                    </Link>
                  </div>
                  <div className="px-2">
                    <Link to={item.link}>
                      <p className="text-justify fs-12 mb-0"> {item.title}</p>
                      <div className="py-2">
                        <button className="btn btn-link p-0 fs-12">
                          Continue reading <span>&rarr;</span>
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* componnent 2 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default BoostingEmployeeAccountability;
