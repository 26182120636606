import React from "react";
import CallToActionSection from "../commonComponent/CallToActionSection";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
const WhyYourComapnyNeeds = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isactive, setisActive] = useState(null);

  const sectionRefs = {
    section0: useRef(null),
    section1: useRef(null),
    section2: useRef(null),
    section3: useRef(null),
    section4: useRef(null),
    section5: useRef(null),
    section6: useRef(null),
    section7: useRef(null),
    section8: useRef(null),
    section9: useRef(null),
    section10: useRef(null),
    section11: useRef(null),
    section12: useRef(null),
  };
  const scrollToSection = (section) => {
    const element = sectionRefs[section].current;
    window.scrollTo({
      top: element.offsetTop - 100,
      behavior: "smooth",
    });
  };

  // Scroll event handler to detect which section is in view
  useEffect(() => {
    const handleScroll = () => {
      const sections = Object.keys(sectionRefs); // Get keys (section names)
      let foundActiveSection = "";

      // Check each section to see if it's in the viewport
      sections.forEach((section) => {
        const element = sectionRefs[section].current;
        const rect = element.getBoundingClientRect();

        // If the section is in the viewport (based on top and bottom visibility)
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          foundActiveSection = section; // Update active section
        }
      });

      if (foundActiveSection !== "") {
        setisActive(foundActiveSection); // Set active section
      }
    };

    // Listen to scroll events
    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const blogTitles = [
    {
      title:
        "Top Benefits of Implementing Attendance Management Software in Your Business",
      img: "assets/img/blog/Top_Benefits_of_Implementing.jpg",
      alt: "Benefits of Implementing Attendance Management image",
      link: "/blog-top-benefits-of-implementing-attendance-management-software-in-your-business",
    },
    {
      title:
        "The Future of Employee Time and Attendance: Trends in Attendance Management Software",
      img: "assets/img/blog/The_Future_of_Employee_Time.jpg",
      alt: "The Future of Employee Time and Attendance image",
      link: "/blog-future-of-employee-time-and-attendance-trends-in-management-software",
    },
    {
      title:
        "How Efficient Attendance Management Boosts Productivity and Reduces Costs",
      img: "assets/img/blog/How_Efficient_Attendance_Management.jpg",
      alt: "How Efficient Attendance Management Boosts image",
      link: "/blog-how-efficient-attendance-management-boosts-productivity-reduces-costs",
    },
    {
      title: "Manual vs. Automated Attendance Management",
      img: "assets/img/blog/Manual_vs._Automated.jpg",
      alt: "Manual vs. Automated Attendance Management image",
      link: "/blog-manual-vs-automated-attendance-management-why-technology-wins",
    },
    {
      title:
        "How Attendance Management Software Improves Accuracy and Saves Time",
      img: "assets/img/blog/How_Attendance_Management.jpg",
      alt: "How Attendance Management Software Improves Accuracy image",
      link: "/blog-how-attendance-management-software-improves-accuracy-and-saves-time",
    },

    {
      title: "Advanced Attendance Tracking Solutions Help Reduce Time Theft",
      img: "assets/img/blog/how-attendance-management-tracking-reduces-time-theft.jpg",
      alt: "how-attendance-management-tracking-reduces-time-theft",
      link: "/blog-advanced-attendance-tracking-solutions-help-reduce-time-theft",
    },
    {
      title:
        "Streamlining HR Operations: The Role of Attendance Management Software",
      img: "assets/img/blog/the-role-of-attendace-management-software-in-hr-operation.jpg",
      alt: "the-role-of-attendace-management-software-in-hr-operation",
      link: "/blog-streamlining-hr-operations-the-role-of-attendance-management-software",
    },
    {
      title: "Key Features to Look for in an Attendance Management System",
      img: "assets/img/blog/key-features-attendance-management-system.jpg",
      alt: "key-features-attendance-management-system",
      link: "/blog-key-features-to-Look-for-in-an-attendance-management-system",
    },
    {
      title: "5 Common Attendance Management Challenges and How to Solve Them",
      img: "assets/img/blog/5-common-attendance-management-challenges-and-solutions.jpg",
      alt: "5-common-attendance-management-challenges-and-solutions",
      link: "/blog-5-common-attendance-management-challenges-and-how-to-solve-them",
    },
    {
      title:
        "Boosting Employee Accountability with Smart Attendance Management Solutions.",
      img: "assets/img/blog/Boosting Employee Accountability with Smart Attendance.jpg",
      alt: "Boosting Employee Accountability with Smart Attendance Management Solutions",
      link: "/blog-boosting-employee-accountability-with-smart-attendance-management-solutions",
    },
    {
      title: "Enhancing Remote Work Management with Attendance Management App",
      img: "assets/img/blog/Enhancing Remote Work Management with Attendance.jpg",
      alt: "Enhancing Remote Work Management with Attendance Management App",
      link: "/blog-enhancing-remote-work-management-with-attendance-management-app",
    },
    {
      title:
        "The Importance of Attendance Management for Compliance and Audits",
      img: "assets/img/blog/The Importance of Attendance Management for.jpg",
      alt: "The Importance of Attendance Management for Compliance and Audits",
      link: "/blog-the-importance-of-attendance-management-for-Compliance-and-audits",
    },
    {
      title:
        "How to Choose the Right Attendance Management Software for Your Business",
      img: "assets/img/blog/How to Choose the Right Attendance Management Software for.jpg",
      alt: "How to Choose the Right Attendance Management Software for Your Business",
      link: "/blog-how-to-choose-the-right-attendance-management-software-for-your-business",
    },
    {
      title:
        "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      img: "assets/img/blog/Real-Time Attendance Tracking Benefits for Large and.jpg",
      alt: "Real-Time Attendance Tracking: Benefits for Large and Small Businesses",
      link: "/blog-real-time-attendance-tracking-benefits-for-large-and-small-businesses",
    },
  ];

  const listItems = [
    {
      id: "section1",
      label: "1. Accurate Employee Time Tracking",
    },
    {
      id: "section2",
      label: "2. Seamlessly Convenient",
    },
    {
      id: "section3",
      label: "3. Advanced Image Authentication",
    },
    {
      id: "section4",
      label: "4. Paperless and Eco-Friendly",
    },
    {
      id: "section5",
      label: "5. Real-Time Insights",
    },
    {
      id: "section6",
      label: "6. User-Friendly Interface",
    },
    {
      id: "section7",
      label: "7. Detailed Compliance Reports",
    },
    {
      id: "section8",
      label: "8. Enhanced Productivity",
    },
    {
      id: "section9",
      label: "9. Remote Work Management",
    },
    {
      id: "section10",
      label: "10. Real-Time Notifications",
    },
    {
      id: "section11",
      label: "11. Scalability for Growing Businesses",
    },
    {
      id: "section12",
      label: "12. Cost-Effectiveness",
    },
  ];

  return (
    <>
      <section className="w-100 bccolor heights">
        <div className="text-center fs-18 mb-3">
          <Link to={"/blogs"}>Blog</Link>/ Why Your Company Needs a Modern
          Attendance Management System
        </div>
        <h1 className="text-center mb-5 ">
          Why Your Company Needs a Modern Attendance Management System
        </h1>
      </section>
      {/* component 2 */}
      <section
        className="p-20 "
        style={{ paddingTop: "50px", paddingBottom: "50px" }}
      >
        <div className="row w-100 justify-content-center gap-2">
          <div className="col-3 card-blog hidden" style={{ height: "640px" }}>
            <ul className="p-2 mt-3 mb-3 ulist" style={{ cursor: "pointer" }}>
              {listItems.map((item, index) => (
                <li
                  key={index}
                  className={`p-1 ${isactive === item.id ? "active" : null}`}
                  onClick={() => {
                    scrollToSection(item.id);
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-12 col-md-12 col-lg-7">
            <div ref={sectionRefs.section0}>
              <p className="fs-16-js">
                The effective management of employee attendance is a critical
                factor to the achievement of efficiency, compliance of and any
                success organization. The conventional methods that include the
                use of paper logs or manual recording systems are not adequate
                to address the rapidly changing needs of today’s
                organizations.Advanced technology-based employee attendance
                management system like TimeTango can change the way businesses
                manage employee attendance.
              </p>
              <p className="fs-16-js">
                Here are the reasons why your company needs a modern attendance
                management system:
              </p>
            </div>
            <div ref={sectionRefs.section1}>
              <h2 className="fs-3  fw-bold">
                1. Accurate Employee Time Tracking
              </h2>
              <p className="fs-16-js mb-2">
                Traditional timekeeping methods are prone to errors,
                manipulation, and inefficiency.
              </p>
              <p className="fs-16-js mb-2 ">
                With{" "}
                <Link to="/about-us" className="text-decoration-none fw-bold">
                  TimeTango
                </Link>
                , employee check-ins and check-outs are effortlessly tracked
                with a secure and reliable system.
              </p>
              <p className="fs-16-js mb-2">
                Advanced{" "}
                <Link
                  to="/activity-tracking"
                  className="text-decoration-none fw-bold"
                >
                  employee time tracking
                </Link>{" "}
                ensures that payroll data is accurate, reducing discrepancies
                and saving HR teams from manual audits.
              </p>
            </div>

            <div ref={sectionRefs.section2}>
              <h2 className="fs-3  fw-bold">2. Seamlessly Convenient</h2>
              <p className="fs-16-js">
                TimeTango provides a simple and intuitive experience for
                associates to check in and out using their mobile devices.
              </p>
              <p className="fs-16-js">
                With just a tap, employees can log their work hours, ensuring
                that time tracking is convenient and accurate.
              </p>
            </div>
            <div ref={sectionRefs.section3}>
              <h2 className="fs-3  fw-bold">
                3. Advanced Image Authentication
              </h2>
              <p className="fs-16-js">
                TimeTango employs advanced image capture technology to verify
                attendance and ensure that employees are physically present.
              </p>
              <p className="fs-16-js">
                This feature eliminates the possibility of time fraud, such as
                proxy check-ins or buddy punching, maintaining integrity in
                timekeeping.
              </p>
              <p className="fs-16-js">
                Authentication is quick and non-intrusive, enhancing user
                experience while maintaining high security.
              </p>
            </div>
            <div ref={sectionRefs.section4}>
              <h2 className="fs-3  fw-bold">4. Paperless and Eco-Friendly</h2>
              <p className="fs-16-js">
                Modern attendance management systems like TimeTango promote
                sustainability by eliminating paper-based processes.
              </p>
              <p className="fs-16-js">
                Switching to an{" "}
                <Link
                  to="/leave-mangement"
                  className="text-decoration-none fw-bold"
                >
                  employee attendance management
                </Link>{" "}
                system not only reduces administrative costs but also
                contributes to a greener planet.
              </p>
              <p className="fs-16-js">
                Going paperless aligns with corporate social responsibility
                goals and helps create a more sustainable workplace.
              </p>
            </div>
            <div ref={sectionRefs.section5}>
              <h2 className="fs-3  fw-bold"> 5. Real-Time Insights</h2>
              <p className="fs-16-js">
                Managers and administrators gain real-time visibility into
                employee attendance.
              </p>
              <p className="fs-16-js">
                With the{" "}
                <Link
                  to="/leave-mangement"
                  className="text-decoration-none fw-bold"
                >
                  TimeTango Attendance Management App
                </Link>
                , admins are notified instantly about check-ins, check-outs, and
                working hours.
              </p>
              <p className="fs-16-js">
                Real-time data empowers HR to make informed decisions, address
                attendance irregularities promptly, and enhance workforce
                management.
              </p>
            </div>
            <div ref={sectionRefs.section6}>
              <h2 className="fs-3  fw-bold">6. User-Friendly Interface</h2>
              <p className="fs-16-js">
                TimeTango is designed for simplicity and efficiency, ensuring
                that associates and admins can use the system with minimal
                training.
              </p>
              <p className="fs-16-js">
                The intuitive design makes it easy for employees to log their
                time and for admins to access and analyze attendance data.
              </p>
              <p className="fs-16-js">
                A user-friendly interface increases adoption rates and ensures a
                smooth transition from manual systems.
              </p>
            </div>
            <div ref={sectionRefs.section7}>
              <h2 className="fs-3  fw-bold">7. Detailed Compliance Reports</h2>
              <p className="fs-16-js">
                TimeTango generates comprehensive compliance reports on employee
                attendance.
              </p>
              <p className="fs-16-js">
                These reports reduce the administrative burden and provide
                actionable insights for workforce optimization.
              </p>
            </div>
            <div ref={sectionRefs.section8}>
              <h2 className="fs-3  fw-bold">8. Enhanced Productivity</h2>
              <p className="fs-16-js">
                Automating attendance tracking frees up time for HR teams,
                allowing them to focus on strategic initiatives rather than
                manual timekeeping.
              </p>
              <p className="fs-16-js">
                Employees can work without the hassle of cumbersome check-in
                processes, boosting overall productivity.
              </p>
              <p className="fs-16-js">
                With accurate{" "}
                <Link
                  to="/activity-tracking"
                  className="text-decoration-none fw-bold"
                >
                  employee time tracking
                </Link>{" "}
                , TimeTango minimizes payroll errors and ensures fair
                compensation for all employees.
              </p>
            </div>
            <div ref={sectionRefs.section9}>
              <h2 className="fs-3  fw-bold">9. Remote Work Management</h2>
              <p className="fs-16-js">
                The rise of remote and hybrid work environments has made it
                essential to track employee attendance irrespective of location.
              </p>
              <p className="fs-16-js">
                The TimeTango Attendance Management App ensures employees can
                log their hours securely from any location.
              </p>
              <p className="fs-16-js">
                This feature enables flexibility while maintaining
                accountability and trust between employers and remote workers.
              </p>
            </div>
            <div ref={sectionRefs.section10}>
              <h2 className="fs-3  fw-bold">10. Real-Time Notifications</h2>
              <p className="fs-16-js">
                TimeTango&#39;s system keeps managers informed with instant
                alerts about early checkouts or extended breaks.
              </p>
              <p className="fs-16-js">
                These notifications help maintain transparency and allow
                supervisors to address attendance anomalies as they occur.
              </p>
              <p className="fs-16-js">
                Real-time monitoring ensures that absenteeism or tardiness is
                managed effectively.
              </p>
            </div>
            <div ref={sectionRefs.section11}>
              <h2 className="fs-3  fw-bold">
                11. Scalability for Growing Businesses
              </h2>
              <p className="fs-16-js">
                As organizations expand, attendance tracking systems need to
                scale seamlessly.
              </p>
              <p className="fs-16-js">
                TimeTango is built to handle growing teams without compromising
                on performance or accuracy.
              </p>
              <p className="fs-16-js">
                Whether your business has 50 employees or 5,000, TimeTango
                adapts to meet your needs.
              </p>
            </div>
            <div ref={sectionRefs.section12}>
              <h2 className="fs-3  fw-bold">12. Cost-Effectiveness</h2>
              <p className="fs-16-js">
                Reducing time theft and automating payroll processes save
                companies significant money over time.
              </p>
              <p className="fs-16-js">
                The elimination of manual processes reduces administrative
                overhead and streamlines operations.
              </p>
              <p className="fs-16-js">
                TimeTango&#39;s cost-effective approach helps businesses
                maximize ROI while delivering exceptional functionality.
              </p>
            </div>
            <div>
              <p className="fs-16-js">
                It is not enough anymore to consider a modern employee
                attendance management system as some added benefit that can
                enhance business performance; it has become a necessity for any
                organization that wants to improve its efficiency and standing
                in today’s market. This is where the TimeTango Attendance
                Management App comes in with features such as real-time
                tracking, enhanced security through authentication and by
                embracing sustainability.
              </p>
            </div>
          </div>
          <div
            className="col-3 hidden"
            style={{
              maxHeight: "100vw",
              overflowY: "auto",
              scrollbarWidth: "none",
              position: "sticky",
              top: 0,
            }}
          >
            <p className="text-center fs-18 fw-bold">Other blog</p>
            <div className="d-flex justify-content-center flex-column gap-4">
              {blogTitles.map((item, index) => (
                <div key={index} className="card-sub-blog">
                  <div>
                    <Link to={item.link}>
                      <img
                        src={item.img}
                        alt={item.alt}
                        className="w-100 h-100"
                      />
                    </Link>
                  </div>
                  <div className="px-2">
                    <Link to={item.link}>
                      <p className="text-justify fs-12 mb-0"> {item.title}</p>
                      <div className="py-2">
                        <button className="btn btn-link p-0 fs-12">
                          Continue reading <span>&rarr;</span>
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* componnent 2 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default WhyYourComapnyNeeds;
